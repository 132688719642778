<template>
  <div>
    <GenericForm
      title="Tipo de Instrumento"
      previousRoute="tipoInstrumento"
      editRoute="tipoInstrumentoEditar"
      :model="model"
      :route="tipoInstrumentoRoute"
      :editing="editing"
      @updateModel="updateModel"
      idModel="id_tipo_item"
      :permissionsToWrite="['rw_material']"
      :permissionsToEdit="['re_material']"
      :permissionsToDelete="['rd_material']"
    >
      <template v-slot="{ validateState, isBusy }">
        <b-row>
          <b-col sm="12" md="6" lg="6" xl="6">
            <b-form-group label="Descrição*" label-for="descricao">
              <b-form-input
                id="descricao"
                v-model="model.descricao"
                autocomplete="off"
                class="invision-input"
                name="descricao"
                :disabled="isBusy"
                v-validate="{ required: true }"
                :state="validateState('descricao')"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
        <div :class="editing ? 'button-foto-editing' : 'button-foto'">
          <Button
            @click="openModalFoto"
            variant="primary-light"
            :text="`${
                editing ? 'Visualizar fotografias' : 'Inserir fotografias'
              } ${
                model.fotos.length ? `(${model.fotos.length})` : ''
              }`"
          />
        </div>
        <Modal
          id="modalPhoto"
          ref="modalPhoto"
          nome="modalPhoto"
          :title="editing ? 'Fotos do Instrumento' : 'Editar fotos do Instrumento'"
          no-close-on-esc
          no-close-on-backdrop
          size="lg"
          centered
        >
          <PhotoForm
            :photos="model.fotos"
            :buttonConfirmar="true"
            downloadRoute="material/downloadFotos"
            @newPhoto="setImage"
            @confirmar="closeModalFoto"
            :photosName="photosName"
          />
        </Modal>
      </template>
    </GenericForm>
  </div>
</template>

<script>

import GenericForm from '@/components/Form/GenericForm';
import Modal from '@/components/Utils/Modal';
import Button from '@/components/Utils/Button';
import PhotoForm from '@/components/WebCam/PhotoForm';

export default {
  components: {
    GenericForm,
    Modal,
    Button,
    PhotoForm,
  },

  props: {
    editing: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      model: {
        id_tipo_item: this.$route.params.id || -1,
        descricao: '',
        quantidade_pecas: null,
        fabricante: '',
        fotos: [],
      },
      tipoInstrumentoRoute: 'tipoItem',
    };
  },

  computed: {
    photosName() {
      if (this.model.descricao || this.model.id_tipo_item !== -1) {
        return `${this.model.descricao}_ID-${this.model.id_tipo_item}`;
      }
      return 'instrumento';
    },
  },

  methods: {
    updateModel(model) {
      this.model = model;
    },

    setImage(images) {
      this.model.fotos = images;
    },

    openModalFoto() {
      this.$refs.modalPhoto.show();
    },

    closeModalFoto() {
      this.$refs.modalPhoto.hide();
    },
  },
};
</script>

<style>
.button-foto {
  position: absolute;
  background-color: transparent;
  bottom: 1px;
  left: 145px;
  z-index: 2;
}

.button-foto-editing {
  position: absolute;
  background-color: transparent;
  bottom: 1px;
  left: 250px;
  z-index: 2;
}
</style>
